import WelcomeText from "./WelcomeText";
import DashboardElements from "./DashboardElements";
import StatsContainer from "./StatsContainer";
import MiddleContainer from "./MiddleContainer";
import BottomContainer from "./BottomContainer";
import { GActions, useGlobalContext } from "../../contexts/GlobalContext";
import { getLeads } from "../../queries/conv";
import { getAllMentorsPerformance, getMentorsByGroup } from "../../queries/mentor";
import { useEffect, useState } from "react";
const DashboardContainer = () => {
  const [state, globalDispatch] = useGlobalContext();
  
  const [leadsDataExcel, setLeadsDataExcel] = useState();
  const user = state.user;
  const dpFetchMentors = async () => {
    if (!state.mentors) {
      globalDispatch({
        type: GActions.SET_MENTORS_LOADING,
        payload: true,
      });
      const result = await getMentorsByGroup();
      console.log("result message", result.mentors);
      globalDispatch({
        type: GActions.SET_MENTORS,
        payload: result.mentors,
      });
      globalDispatch({
        type: GActions.SET_MENTORS_LOADING,
        payload: false,
      });
      if (!result.ok) {
        globalDispatch({
          type: GActions.SET_SNACKBAR,
          payload: {
            open: true,
            message: result.message,
            severity: "error",
          },
        });
      }
    }
  };

  const dpFetchConversations = async () => {
    if (!state.conversations) {
      globalDispatch({
        type: GActions.SET_CONVERSATION_LOADING,
        payload: true,
      });
      const resultTEST = await getAllMentorsPerformance(state.user?.privilege);
      const result = await getLeads();
      const leads = result.leads;
      console.log("mentorPerformances", resultTEST.mentorPerformances)
      const leadDataUniversity = leads.map((lead) => ({
        Name: lead.name,
        Email: lead.email,
        Cycle: lead.year,
        Mentor: lead.mentorName,
        "Last Response Mentor": lead.lastResponseMentor,
        "Last Response Student": lead.lastResponseStudent,
        "Created On": new Date(lead.createdAt),
      }));
      
      setLeadsDataExcel(leadDataUniversity);
      globalDispatch({
        type: GActions.SET_ALL_MENTOR_PERFORMANCE,
        payload: resultTEST.mentorPerformances,
      });

      globalDispatch({
        type: GActions.SET_CONVERSATIONS,
        payload: leads,
      });
      globalDispatch({
        type: GActions.SET_CONVERSATION_LOADING,
        payload: false,
      });
      if (!result.ok) {
        globalDispatch({
          type: GActions.SET_SNACKBAR,
          payload: {
            open: true,
            message: result.message,
            severity: "error",
          },
        });
      }
    }
  };
  const fetchOverviewData = async () => {
    console.log("user: ", state.user?.privilege);

    await dpFetchMentors();
    if (state.user?.privilege !== "Fisher" && state.user?.privilege !== "PACE" ) {
      // await fetchGroups();
      await dpFetchConversations();
    }
  };

  useEffect(() => {
    fetchOverviewData();
  }, []);
  return (
    <>
      <DashboardElements.DasboardContainer>
        <WelcomeText />
        <StatsContainer />
        <MiddleContainer />
        <BottomContainer leadsDataExcel = {leadsDataExcel} />
      </DashboardElements.DasboardContainer>
    </>
  );
};

export default DashboardContainer;
