import React, { useState, useRef, useEffect } from "react";
import Chart from "chart.js/auto";
import { useGlobalContext } from "../../contexts/GlobalContext";

const BarGraph = () => {
  const chartRef = useRef(null);
  const myChartRef = useRef(null);
  const [firstBarActive, setFirstBarActive] = useState(true);
  const [otherBarsActive, setOtherBarsActive] = useState(false);
  const [state] = useGlobalContext();

  useEffect(() => {
    const universitiesCountriesVisited = {
      usc: {
        labels: [
          "India",
          "United States",
          "Nigeria",
          "Brazil",
          "Ghana",
          "Saudi Arabia",
          "France",
          "Indonesia",
          "Philippines",
        ],
        data: [45.68, 22.61, 4.09, 3.75, 2.16, 1.93, 1.82, 1.14, 0.91],
      },
      Magnolia: {
        labels: [
          "India",
          "United States",
          "Nigeria",
          "Brazil",
          "Ghana",
          "Saudi Arabia",
          "France",
          "Indonesia",
          "Philippines",
        ],
        data: [45.68, 22.61, 4.09, 3.75, 2.16, 1.93, 1.82, 1.14, 0.91],
      },
      admin: {
        labels: [
          "India",
          "United States",
          "Nigeria",
          "Brazil",
          "Ghana",
          "Saudi Arabia",
          "France",
          "Indonesia",
          "Philippines",
        ],
        data: [45.68, 22.61, 4.09, 3.75, 2.16, 1.93, 1.82, 1.14, 0.91],
      },
      Dickinson: {
        labels: [
          "United States",
          "India",
          "Vietnam",
          "Pakistan",
          "Nigeria",
          "Uzbeksitan",
          "Zambia",
          "Netherlands",
          "Bangladesh",
        ],
        data: [50.82, 9.02, 5.74, 5.33, 4.1, 2.87, 2.05, 2.05, 2.05],
      },
      vanderbilt: {
        labels: [
          "India",
          "United States",
          "Nigeria",
          "Pakistan",
          "Germany",
          "Bangladesh",
          "Brazil",
          "Turkey",
          "China",
        ],
        data: [33.54, 15.24, 6.71, 5.49, 4.27, 3.05, 3.05, 3.05, 3.05],
      },
      "GW Law": {
        labels: [
          "United States",
          "India",
          "Ghana",
          "Nigeria",
          "Pakistan",
          "Uganda",
          "China",
          "Germany",
          "Turkey",
        ],
        data: [26.13, 13.94, 12.89, 9.06, 5.92, 3.14, 2.79, 1.39, 1.39],
      },
      "emory": {
        labels: [
          "United States",
          "India",
          "Pakistan",
          "South Korea",
          "Brazil",
          "Poland",
          "Turkey",
          "Nigeria",
          "Uganda",
        ],
        data: [53.33, 24.44, 3.33, 3.33, 2.22, 1.11, 1.11, 1.11, 1.11],
      },
      Fisher: {
        labels: [
          "India",
          "United States",
          "Nigeria",
          "Brazil",
          "Ghana",
          "Saudi Arabia",
          "France",
          "Indonesia",
          "Philippines",
        ],
        data: [0, 0, 0, 0, 0, 0, 0, 0, 0],
      },
      UCI: {
        labels: [
          "India",
          "United States",
          "Nigeria",
          "Brazil",
          "Ghana",
          "Saudi Arabia",
          "France",
          "Indonesia",
          "Philippines",
        ],
        data: [0, 0, 0, 0, 0, 0, 0, 0, 0],
      },
      PACE: {
        labels: [
          "India",
          "United States",
          "Nigeria",
          "Brazil",
          "Ghana",
          "Saudi Arabia",
          "France",
          "Indonesia",
          "Philippines",
        ],
        data: [0, 0, 0, 0, 0, 0, 0, 0, 0],
      },
      "ucla": {
        labels: [
          "United States",
          "India",
          "Pakistan",
          "South Korea",
          "Brazil",
          "Poland",
          "Turkey",
          "Nigeria",
          "Uganda",
        ],
        data: [53.33, 24.44, 3.33, 3.33, 2.22, 1.11, 1.11, 1.11, 1.11],
      },
      "gateway": {
        labels: [
          "United States",
          "India",
          "Pakistan",
          "South Korea",
          "Brazil",
          "Poland",
          "Turkey",
          "Nigeria",
          "Uganda",
        ],
        data: [0, 0, 0, 0, 0, 0, 0, 0, 0],
      },
    };
    const labels = [
      "India",
      "United States",
      "Nigeria",
      "Brazil",
      "Ghana",
      "Saudi Arabia",
      "France",
      "Indonesia",
      "Philippines",
    ];
    const data = {
      labels: universitiesCountriesVisited[state.user?.privilege].labels,
      datasets: [
        {
          label: "Dataset 1",
          //   data: [45.68, 22.61, 4.09, 3.75, 2.16, 1.93, 1.82, 1.14, 0.91],
          data: universitiesCountriesVisited[state.user?.privilege].data,
          borderColor: "#4880FF",
          backgroundColor: "#9BCDFC",
          hoverBackgroundColor: "#4880FF",
        },
      ],
    };

    const options = {
      scales: {
        x: {
          display: true,
          grid: { display: false },
          ticks: {
            maxRotation: 0,
            autoSkip: false,
            maxLines: 1,
            font: {
              size: 10,
              weight: 600,
              color: "#000000",
            },
          },
        },
        y: {
          ticks: {
            callback: function (value, index, values) {
              return value === 0
                ? "0%"
                : value === 30
                ? "30%"
                : value === 50
                ? "50%"
                : value === 100
                ? "100%"
                : "";
            },
            font: {
              weight: 700,
            },
          },
          grid: { display: false },
        },
      },
      plugins: {
        legend: { display: false },
        tooltip: {
          enabled: false, // Disable tooltips
        },
      },
      interaction: {
        intersect: false,
        mode: "index",
        axis: "y",
      },
      elements: {
        bar: {
          backgroundColor: "#9BCDFC",
          hoverBackgroundColor: "#4880FF",
          borderSkipped: "bottom",
          borderRadius: 6,
          hover: {
            animationDuration: 0, // Disable hover animation
          },
        },
      },
      hover: {
        mode: "nearest",
        intersect: true,
        onHover: (event, chartElement) => {
          const [{ raw: value, x, y }] = chartElement;
          const ctx = myChartRef.current.ctx;

          // Clear any previous text
          ctx.clearRect(0, 0, ctx.canvas.width, ctx.canvas.height);

          // Draw the value on top of the bar
          ctx.font = "12px sans-serif";
          ctx.fillStyle = "black";
          ctx.textAlign = "center";
          ctx.fillText(value + "%", x, y - 5); // Adjust vertical position as needed

          const activeBars = chartElement.length > 0 ? chartElement[0] : null;
          if (!activeBars) {
            // No bar is active, set the first bar active
            setFirstBarActive(true);
            setOtherBarsActive(false);
          } else {
            // At least one bar is active
            setFirstBarActive(false);
            setOtherBarsActive(true);
          }
        },
      },
    };

    const config = {
      type: "bar",
      data: data,
      options: options,
      plugins: [
        {
          id: "customValueDisplay",
          afterDraw: (chart) => {
            const ctx = chart.ctx;
            ctx.save();

            ctx.fillStyle = "#333"; // Set text color
            ctx.font = '700 12px "Nunito Sans"'; // Set font style
            ctx.textAlign = "center";
            ctx.textBaseline = "bottom";

            const activeBars = chart.getElementsAtEventForMode(
              "nearest",
              { intersect: true },
              false
            );
            setFirstBarActive(activeBars.length === 0);
            setOtherBarsActive(activeBars.length > 0);

            chart.data.datasets.forEach((dataset, i) => {
              const meta = chart.getDatasetMeta(i);
              meta.data.forEach((bar, index) => {
                const value = dataset.data[index];
                if (
                  value !== null &&
                  ((otherBarsActive && index === 0) || bar.active)
                ) {
                  ctx.fillText(value + "%", bar.x, bar.y - 5);
                }
              });
            });

            ctx.restore();
          },
        },
      ],
    };

    const ctx = chartRef.current.getContext("2d");
    const parentHeight = chartRef.current.parentElement.clientHeight;
    const parentWidth = chartRef.current.parentElement.clientWidth;
    ctx.canvas.width = parentWidth;
    ctx.canvas.height = parentHeight;

    const myChart = new Chart(ctx, config);
    myChartRef.current = myChart;

    myChart.setActiveElements([{ datasetIndex: 0, index: 0 }]);
    window.dispatchEvent(new Event("resize"));

    return () => myChart.destroy();
  }, []);

  useEffect(() => {
    const handleResize = () => {
      const parentHeight = chartRef.current.parentElement.clientHeight;
      const parentWidth = chartRef.current.parentElement.clientWidth;
      chartRef.current.height = parentHeight * 0.95;
      chartRef.current.width = parentWidth * 0.9;
      myChartRef.current.resize();
    };

    handleResize();
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  return (
    <div style={{ marginTop: "2rem", width: "100%", height: "80%" }}>
      <canvas
        id="myChart"
        ref={chartRef}
        style={{ width: "100%", height: "100%" }}
      ></canvas>
    </div>
  );
};

export default BarGraph;
